.positsContainer{
    margin-right: 30px;
}
.positInstruction{
    background-color:#FBE364;
    box-shadow:var(--shadow-1);
    font-family:"Courier New", sans-serif;
    margin-bottom: 12px;
    text-align:left;
}
.positInstruction>*{
    padding:4px 6px;
}
.positInstruction>div:first-child{
    background-color: #efd962;
    font-size:.8em;
    display:flex;
    justify-content: space-between;
}
.positInstruction .icons>svg{
    cursor:pointer;
    color:var(--main-color);
}
