.dropDownBackground {
	position: fixed;
	background-color: transparent;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2;
}
.dropDown_panel {
	position: fixed;
	background-color: white;
	display: grid;
	z-index: 3;
	border-radius: 8px;
	box-shadow: 0 4px 16px 0 rgba(75, 75, 75, 0.233);
}

.withCorner {
	position: relative;
}

.withCorner:after {
	content: '';
	width: 10px;
	background-color: red;
}
