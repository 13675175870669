.dropDown_option {
	display: grid;
	width: 100%;
	align-items: center;
	border-bottom: 2px solid rgb(214, 214, 214);
	padding: 12px 25px;
	cursor: pointer;
}
.dropDown_option.disabled{
	opacity:.4;
	cursor:default;
}
.dropDown_option:not(.disabled):hover {
	background-color: rgb(199, 199, 199);
}
.dropDown_option:first-child:hover {
	border-radius: 8px 8px 0 0;
}

.dropDown_option:last-child:hover {
	border-radius: 0 0 8px 8px;
}

.dropDown_option:last-child {
	border: none;
}
.dropDown_option.withIcon {
	grid-template-columns: 1.5em 1fr;
}
