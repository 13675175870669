.user-area-container {
	color: black;
	background-color: rgb(240, 240, 240);
	min-height: 100vh;
}

.navBar {
	width: 100%;
	background-color: white;
	display: flex;
	align-content: space-between;
	align-items: center;
	padding: 20px 100px;
	box-shadow: 1px 1px 5px rgb(173, 173, 173);
}

.black-logo {
	align-items: left;
	width: 100%;
	height: 100%;
	max-width: 300px;
}

.searchIcon {
	color: grey;
	margin: 10px;
}
.searchBar {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
}

.searchBar input {
	width: 30%;
	border: none;
	box-shadow: 0px 1px 0px 0px lightgrey;
	padding: 5px;
	border-radius:0;
}
.searchBar input:focus {
	outline: none;
}
.searchBar input::placeholder {
	opacity: 100;
}

.romboIcon {
	width: 40px;
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	padding: 1px;
	margin-left: 10px;
}

.userID {
	display: flex;
	align-items: center;
	height: 80%;
	min-width: 200px;
	justify-content: center;
	margin-left: 50px;
}
.userIDButton {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 20px;
	letter-spacing: 2px;
	margin-left: 20px;
	min-width: 140px;
	width: 100%;
	border: none;
	background-color: transparent;
	padding: 0px 15px;
	outline: none;
	cursor: pointer;
}

.arrowDownIcon {
	color: grey;
	width: 100%;
}

.noBottomBorder {
	border-bottom: none;
}

.extendRight {
	margin-right: 300px;
	text-align: left;
}
