.positsContainer{
    margin-right:30px;
}
.nivelContainer{
    display:grid;
    grid-template-columns:300px 1fr;
    margin-top:30px;
    height:100%;
}


