.table {
	font-size:.9em;
	border-collapse:collapse;
	width:100%;
	margin:0 45px;
	height:max-content;
}
.table th, .table td{
	cursor:default;
}
.table thead>tr{
	padding:8px;
	border-bottom: 2px solid rgb(44, 44, 44);
	color: #717171;
	position:relative;
}
.table th{
	padding:8px;
	font-weight: 600;
}
.table th:after{
	content:"\276E";
	display: inline-block;
	margin-left:6px;
	opacity:0;
}
.table th:global.orderedBy:after{
	opacity:1;
}
.table th:global.orderedBy:global.up:after{
	transform:rotate(90deg);
}
.table th:global.orderedBy:global.down:after{
	transform:rotate(-90deg);
}

.table tbody td{
	padding:4px;
	text-align: center;
}
.table tbody td:first-child{
	display:grid;
	grid-template-columns:100px  1fr 50px;
	align-items: center;
}
.table tbody tr{
	border-bottom: 1px solid rgb(182, 182, 182);
	transform:scale(1);
}

