.container{
    position:fixed;
    z-index:1090;
    top:0;
    left:50%;
    opacity:1;
    padding:15px 30px;
    display:flex;
    align-items: center;
    color:#2c2c2c;
    animation: showNotification .25s ease-out forwards;
}
.container.hide{
    animation: hideNotification .25s ease-in forwards;
}

@keyframes showNotification{
    from{
        opacity:0;
        transform:translate(-50%, 0px);
    }
    to{
        opacity: 1;
        transform: translate(-50%, 50px);
    }
}

@keyframes hideNotification {
    from{
        opacity:1;
        transform:translate(-50%, 50px);
    }
    to{
        opacity: 0;
        transform: translate(-50%, -50px);
    }
}