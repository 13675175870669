.plusButton {
	right: 30px;
	bottom: 20px;
	position: fixed;
	border: none;
	background-color: Transparent;
	cursor: pointer;
}

.plusButtonMenu {
	display: flex;
	position: fixed;
	flex-direction: column;
	justify-content: space-between;
	background-color: white;
	height: fit-content;
	width: 100%;
	max-width: 220px;
	min-height: 180px;
	bottom: 60px;
	right: 40px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	border-radius: 15px;
	z-index: 1;
}

.plusButtonMenu div {
	display: flex;
	justify-content: space-around;
	align-items: center;
	border: none;
	background-color: transparent;
	color: black;
	cursor: pointer;
	border-bottom: 2px solid rgb(224, 224, 224);
}
.plusButtonMenu div:last-child {
	border: none;
}

.plusButtonMenu label {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: transparent;
	color: black;
	cursor: pointer;
}

.plusButtonMenu input {
	display: none;
}

.plusButtonMenu span {
	width: 100%;
	height: 100%;
	font-size: 17px;
}
.plusButtonIcon {
	align-items: center;
	justify-content: center;
	width: 30%;
	padding: 10px;
}

.userArea_container {
	padding: 15px;
	height: calc(100% - 85px);
}

/*--- MODAL AÑADIR UNA CARPETA | RENOMBRAR UN ELEMENTO ---*/
.addFolderModal {
	color: black;
	display: grid;
	grid-row-gap: 20px;
}
.addFolderModal input, .addFolderModal textarea {
	border: solid 1px black;
	min-width: 400px;
	border-radius: 8px;
	padding: 8px;
}
.addFolderModal textarea{
	min-height:200px;
}
.modalBtn {
	border-radius: 5px;
	padding: 6px 12px;
	color: white;
	border: none;
	font-weight: bold;
	background-color: transparent;
	min-width: 100px;
}
.modalBtns_container {
	display: flex;
	justify-content: space-evenly;
}

.addFolderModal .modalTitle {
	text-align: center;
	font-weight: bold;
}
.addFolderModal .modalTitle > span {
	position: relative;
}
.addFolderModal .modalTitle > span:after {
	content: '';
	position: absolute;
	bottom: -4px;
	width: 100%;
	background-color: var(--main-color);
	height: 2px;
	left: 0;
}
/* --- SUBCABECERA USER AREA (BREADCRUMB,  CAMBIOS DE VISTA, ETC...)*/
.userArea__header {
	display: flex;
	align-items: center;
	position: relative;
	line-height: 32px;
}
.breadcrumb {
	display: flex;
	flex-wrap:wrap;
	align-items: center;
	margin:0 45px;
	width: 100%;
	overflow:hidden;
}
.breadcrumb_option {
	display:flex;
	align-items: center;
	cursor: pointer;
	overflow:hidden;
}
.breadcrumb_option>span{
	overflow:hidden;
	text-overflow: ellipsis;
	text-align:left;
	min-width:20px;
	white-space:nowrap;
	max-width:150px;
}

.displayMenu{
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.orderingMenu{
	display: flex;
	justify-content: flex-end;
}
.orderByMenu{
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.viewModeToggles {
	display: flex;
	align-items: center;
}

.viewModeSVG{
	cursor:pointer;
}
#orderByDropdown {
	text-align: start;
}

/*---- DRAGGING ----*/
.isDragging{
	position:relative;
}
.isDragging:after{
	content:"";
	position:absolute;
	top:-2px;
	left:-2px;
	height:calc(100% + 4px);
	width:calc(100% + 4px);
	border:solid 1px #03a9f4;
	background-color:#03a9f433;
	box-shadow:0 4px 14px 0 rgba(44,62,80,.16);
	border-radius:4px;
	padding:8px;
}
