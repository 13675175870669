.container{
    color:var(--main-color);
}
.title{
    font-weight:bold;
}
.infoText{
    color:#60656a;
}
.linkContainer{
    padding:15px;
    background-color: #f3f3f3;
    width: fit-content;
    margin: 0 auto;
    font-size:.9em;
    font-weight: bold;
    display:flex;
    align-items:center;
}
.linkContainer>img{
    margin-left:8px;
    height:20px;
    cursor:pointer;
}