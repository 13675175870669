.main-container-login {
	background-image: url('/src/assets/pexels-aleksejs-bergmanis-681335\ 1.png');
	background-size: cover;
	height: 100vh;
	width: auto;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.bar-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 80%;
	background-color: rgba(0, 0, 0, 0.692);
	color: white;
	padding: 50px 200px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	max-height: 330px;
}
.login-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 10px;
}

.login-container h2 {
	text-decoration: underline;
	padding-left: 35px;
	margin-bottom: 8px;
}

.login-container span {
	display: flex;
	flex-direction: row;
	padding: 10px;
}

.login-container input {
	background-color: rgba(0, 0, 0, 0);
	color: white;
	outline: none;
	border: none;
	box-shadow: 0px 0px 0px 2px rgb(255, 255, 255);
	border-radius: 5px;
	height: 100%;
	padding: 5px;
	width: 200px;
}

.login-container input.error {
	box-shadow: 0px 0px 0px 2px rgb(255, 0, 0);
}

.error-message {
	font-size: 13px;
	color: red;
	justify-content: center;
}

.icon {
	margin-right: 10px;
}

.login-container button {
	min-height: 25px;
	align-self: center;
}
.title-container {
	display: flex;
	flex-direction: column;
}
