.gridFichero_item{
    border:solid 1px var(--main-color);
    border-radius:4px;
    padding: 12px;
    display:grid;
    grid-template-columns: 50px 1fr 30px;
    align-items: center;
    grid-column-gap: 15px;
    text-align:left;
    cursor:pointer;
    z-index:1;
    position: relative;
}

.gridFichero_item>span{
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.gridFichero_item>img{
    height:1.6em;
}
.estadoIndicator{
    height:16px;
    width:16px;
    border-radius:50%;
    cursor:pointer;
}
.estadoIndicator:hover{
    box-shadow:var(--shadow-1);
    border:solid 1px var(--main-color);
}

.disabled{
    opacity:.3;
    color:grey;
    cursor:not-allowed;
}

.rutaBusqueda{
    position: absolute;
    left: 5px;
    font-size: .75em;
    color: #a8a7a7;
    bottom: 4px;
    padding: 0 !important;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor:pointer;
}

.selected{
    border:solid 1px rgb(3, 169, 244);
    background-color: rgba(3, 169, 244, .2);
}