.loading-screen {
	display: flex;
	padding: 400px;
	background-color: black;
	width: 100%;
	height: 100vh;
}

.fading-effect {
	animation: showHide 2s ease-in alternate infinite;
}

@keyframes showHide {
	0% {
		opacity: 30%;
	}
	100% {
		opacity: 100%;
	}
}
