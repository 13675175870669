/*--- CHECKBOX Y RADIOS ---*/
.checkContainer{
    display: flex;
    align-items: center;
    position: relative;
    padding-left: calc(1em + 8px);
    /*margin: 0 auto 16px;*/
    cursor: pointer;
    user-select: none;
    line-height:1em;
    width:fit-content;
    justify-content: center;
}
.checkContainer.disabled{
    opacity:.4;
}
.checkContainer>input{
    opacity:0;
    position:absolute;
    cursor:pointer;
    height: 0;
    width: 0;
}
.checkContainer .checkmark{
    position: absolute;
    left: 0;
    height: 1em;
    width: 1em;
    background-color: #FFFFFF;
    border:solid 1px var(--main-color);
    border-radius:2px;
    /*box-shadow:0 3px 6px 0 rgba(0, 0 , 0 ,0.16);*/
}
.checkContainer .checkmark:after{
    position:absolute;
    content:'';
    width:85%;
    height:85%;
    transition:transform .2s ease-in-out;
    transform: translate(-50%,-50%) scale(0);
    left:50%;
    top:50%;
    transform-origin: center;
    border-radius:2px;
}
.checkContainer input:checked ~ .checkmark:after{
    background-color: var(--main-color);
    transform: translate(-50%,-50%) scale(1);
}

.checkContainer .checkmark.rounded, .checkContainer .checkmark.rounded:after{
    border-radius:50%;
}
