.title{
    font-size:1.3em;
    margin:15px auto;
    position:relative;
    display:block;
    width:max-content;
    white-space: nowrap;
}
.title:after{
    content:"";
    position:absolute;
    background-color: var(--main-color);
    width:100%;
    height:2px;
    left:0;
    bottom:-3px;
}
.form{
    display:grid;
    grid-row-gap: 20px;
    width:400px;
    margin:0 auto;
}
