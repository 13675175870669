.container{
    display:grid;
    grid-row-gap:12px;
}
.historyNavigation{
    display:flex;
    align-items:center;
    gap:4px;
    color:var(--main-color);
}
.historyNavigation>svg{
    box-sizing: content-box;
    padding:3px 6px;
    font-size:.9em;
    border-radius:4px;
    border:solid 1px var(--main-color);
    cursor:pointer;
}
.historyNavigation>svg.inactive{
    opacity:.2;
    cursor:default;
}
.firstRow{
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.filters{
    display:flex;
}
.filters>label{
    margin:0 8px;
    color:var(--main-color);
    font-size:14px;
}
.navigation{
    display:flex;
}

.orderRow{
    display:flex;
}

.orderRow>div{
    margin:0 8px;
}