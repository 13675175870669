.mainContainer{
    display:grid;
    gap:20px;
    transform:translateX(25px);
}
.mainContainer:first-child{
    transform:translateX(0);
}
.subContainer{
    display:grid;
    gap:12px;
}

.optionSelector{
    display:flex;
    align-items: center;
    gap:15px;
}
.readOnlySelector{
    font-size:.75em;
}