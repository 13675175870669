.mainContentNivel {
	margin: 20px 100px;
	background-color: lightgrey;
	padding: 20px;
	border-radius: 10px;
}

.gridFicheros{
	display:grid;
	grid-template-columns: repeat(2, 400px);
	grid-template-rows: repeat(auto-fill,65px);
	grid-gap:30px;
	margin:0 auto;
	width:fit-content;
	min-height:calc(100vh - 200px);
}
.gridFicheros.isDragging .gridFichero_item.fileItem{
	pointer-events: none;
}


/*---- VISTA TABLA ----*/
.tablaFicheros{
	display:flex;
	justify-content: center;
	min-height:calc(100vh - 200px);
}


/*---- ELEMENTOS SELECCIONADOS ----*/
.elementsSelectedInfo{
	position:fixed;
	left:15px;
	bottom:15px;
	background-color:var(--main-color);
	box-shadow: var(--shadow-1);
	color:white;
	font-size:.9em;
	padding:8px 12px;
	display:flex;
	align-items:center;
	gap:12px;
}
.elementsSelectedInfo>svg{
	box-sizing: content-box;
	background-color:rgba(223, 223, 223, .2);
	height:12px;
	width:12px;
	padding:4px;
	border-radius:50%;
	cursor:pointer;
}