:root{
 --main-color:#2C3E50;
  --shadow-1:0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  --sinProcesar:#cacaca;
  --pendiente:#ffeb00;
  --aceptado:#85CA4F;
  --rechazado:#CA2F11;
}

.App {
  text-align: center;
  line-height: 1.5;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button{
  cursor:pointer;
}
button:focus{
  outline:none;
}
button:disabled{
  opacity:.5;
  cursor:default;
}
input:focus{
  outline:none;
}
textarea::placeholder{
  font-family:"Helvetica", sans-serif;
}
input:focus, textarea:focus{
  outline:var(--main-color) auto 1px;
}

input{
  padding:8px;
  border-radius: 8px;
  border:solid 1px black;
}
input.error{
  border:solid 1px red;
}
input.error:focus{
  outline:red;
}
label.input_error{
  color:red;
  font-size:.8em;
  display: block;
  margin:4px;
  text-align:left;
}


select{
  border:none;
  color:var(--main-color);
}
select:focus{
  outline:none;
}