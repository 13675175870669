.mainContainer{
    margin:30px;
}
.container{
    width:fit-content;
    min-width:60%;
    font-size:.9em;
    margin:0 auto;
}

.integralContainer{
    margin:35px 0 0;
    text-align:left;
    padding-bottom:35px;
}
.integralContainer:not(:last-child){
    border-bottom:solid 2px var(--main-color);
}
.headerRow{
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom:12px;
}
.integralTitle{
    font-weight:bold;
    letter-spacing: 2px;
}

.usersTable{
    box-shadow:var(--shadow-1);
}
.userRow{
    display:grid;
    grid-template-columns: 50% auto auto;
    padding:12px;
}
.userRow:nth-child(even){
    background-color:#e8f3ff;
}
.userRow:not(:last-child){
    border-bottom:solid 1px var(--main-color);
}

.userRow svg:hover>path{
    fill:currentColor;
    stroke:none;
}
.userRow svg>path{
    fill:none;
    stroke:currentColor;
    stroke-width: 25;
}

.iconBtn{
    border:solid 1px #9099A0;
    padding:4px 8px;
    border-radius:4px;
    cursor:pointer;
}
.iconBtn:hover{
    background-color:var(--main-color);
}
.iconBtn:hover svg{
    color:white;
}
.btnHeader{
    text-align: right;
}