.loaderWraper{
    position: fixed;
    top:0;
    left:0;
    z-index:1060;
    height:100%;
    width:100%;
}
.loaderBck{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index:1;
    background-color:rgba(238, 238, 238, 0.45);
}
.loaderContainer{
    z-index:2;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 100px;
    transform: translate(-50%, -50%);
}
.circularLoader {
    width: 100px;
    height: 100px;
}
.loaderPath {
    animation: color 1s ease-in-out infinite;
}
.uploadInfo{
    left:50%;
    top:50%;
    position:absolute;
    transform:translate(-50%, -50%);
    margin-top:100px;
    text-align: center;
}
.uploadIndicatorContainer{
    width: 300px;
    height: 4px;
    background-color: #c8c6c6;
    border-radius: 4px;
    margin-bottom:20px;
}
.uploadPercent{
    background-color:var(--aceptado);
    height:4px;
    border-radius: 4px;
}
.uploadInfo span{
    font-weight:bold;
    color:var(--main-color);
}

@keyframes color {
    0% {
        stroke: #0ab1ab;
    }
    40% {
        stroke: #5018d3;
    }
    60% {

        stroke: #b7244f;
    }
    90% {
        stroke: #ee6c4e;
    }
    100%{
        stroke: #0ab1ab;
    }
}
